var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo "},[_c('h1',{staticClass:"brand-text text-primary ml-1",staticStyle:{"font-size":"35px"}},[_vm._v(" PayPSI "),_c('small',[_vm._v("Fakultas Psikologi")])]),_c('br')]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),(_vm.st=='0')?_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1  mt-5",attrs:{"title-tag":"h2"}},[_vm._v(" Registrasi Akun 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Sistem PayPSI Fakultas Psikologi ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"NIM","label-for":"nim"}},[_c('validation-provider',{attrs:{"name":"Nomor Induk Mahasiswa","rules":"required|length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nim","name":"nim","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"F.111.13.0001"},on:{"input":function($event){return _vm.cekNim()}},model:{value:(_vm.registrasi.nim),callback:function ($$v) {_vm.$set(_vm.registrasi, "nim", $$v)},expression:"registrasi.nim"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3276950977)}),_vm._v(" "+_vm._s()+" "),_c('small',{staticStyle:{"font-size":"10px"}},[_c('i',[_vm._v("** Nim akan dignakan sebagai username")])])],1)],1),(_vm.cek_nim =='1')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Mahasiswa","label-for":"Nama_Mahasiswa"}},[_c('validation-provider',{attrs:{"name":"Nama kelompot Perikanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"Nama_Mahasiswa","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Nama Lengkap Mahasiswa"},model:{value:(_vm.registrasi.nm_lengkap),callback:function ($$v) {_vm.$set(_vm.registrasi, "nm_lengkap", $$v)},expression:"registrasi.nm_lengkap"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,908003526)})],1)],1):_vm._e()],1),(_vm.cek_nim =='0')?_c('b-row',[_c('b-col',[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],staticClass:"mb-0",attrs:{"variant":"danger","show":"show"}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_vm._v(" "+_vm._s(_vm.pesan)+" ")],1)])],1)],1):_vm._e(),(_vm.cek_nim =='1')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tahun Angkatan","label-for":"th_angkatan"}},[_c('validation-provider',{attrs:{"name":"th_angkatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"th_angkatan","type":"number","size":"sm","name":"th_angkatan","state":errors.length > 0 ? false:null,"placeholder":"2014"},model:{value:(_vm.registrasi.th_angkatan),callback:function ($$v) {_vm.$set(_vm.registrasi, "th_angkatan", $$v)},expression:"registrasi.th_angkatan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,149866909)})],1),_c('b-form-group',{attrs:{"label":"No Hp/Wa","label-for":"register-no_hp"}},[_c('validation-provider',{attrs:{"name":"no_hp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-no_hp","type":"number","size":"sm","name":"register-no_hp","state":errors.length > 0 ? false:null,"placeholder":"085742287875"},model:{value:(_vm.registrasi.no_hp),callback:function ($$v) {_vm.$set(_vm.registrasi, "no_hp", $$v)},expression:"registrasi.no_hp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,336357965)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kelas","label-for":"kelas"}},[_c('validation-provider',{attrs:{"name":"kelas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","state":errors.length > 0 ? false:null,"options":_vm.rs_kelas},model:{value:(_vm.registrasi.kelas),callback:function ($$v) {_vm.$set(_vm.registrasi, "kelas", $$v)},expression:"registrasi.kelas"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2207858740)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.registrasi.email),callback:function ($$v) {_vm.$set(_vm.registrasi, "email", $$v)},expression:"registrasi.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,450710917)})],1)],1)],1):_vm._e(),(_vm.cek_nim =='1')?_c('div',[_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","size":"sm","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.registrasi.password),callback:function ($$v) {_vm.$set(_vm.registrasi, "password", $$v)},expression:"registrasi.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1287717944)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Konformasi Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","size":"sm","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.registrasi.password2),callback:function ($$v) {_vm.$set(_vm.registrasi, "password2", $$v)},expression:"registrasi.password2"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3574055946)})],1)],1):_vm._e(),(_vm.registrasi.password2 != _vm.registrasi.password)?_c('div',[_c('i',{staticClass:"text-danger"},[_vm._v("*** Maaf password konfirmasi belum sesuai dengan password..!!")])]):_vm._e(),(_vm.registrasi.password2 == _vm.registrasi.password)?_c('div',[(_vm.cek_nim =='1')?_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('i',{staticClass:"fa fa-user mr-1"}),_vm._v(" Sign up ")]):_vm._e()],1):_vm._e()],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Sudah Memiliki Akun ?")]),_c('router-link',{attrs:{"to":"/"}},[_c('span',[_vm._v(" Silahkan Login disini")])])],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" PayPSI By Psikologi TECT ")])])],1)],1):_vm._e(),(_vm.st == '1')?_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"6"}},[(_vm.st == '1')?_c('registrasi_info',{attrs:{"info":_vm.info}}):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }