<template>
    <div  > 
  <b-card-title class="mb-1">
    Selamat akun anda berhasil diregistrasikan di sistem Pay Trace <b>{{info.message}}</b>   👋
  </b-card-title> 
 
  <b-card-text style="text-align: justify">
  Silahkan login ke sistem menggunakan username "NIM" dan password sesuai yang anda registrasikan. dan segera lengkapi data-data profil anda
  </b-card-text>

<router-link to="/">
<b-button
        block
        type="submit"
        variant="primary"
      >
        <i class="fa fa-sign-in mr-1"></i>klik disini untuk masuk ke halaman login 
      </b-button>
</router-link>
 
  <p class="text-center mt-2">
    <b-link :to="{name:'auth-login-v1'}">
      <feather-icon icon="ChevronLeftIcon" /> Back to login
    </b-link>
  </p>
 
    </div>
  
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import {
    BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
  } from 'bootstrap-vue'
  import { required } from '@validations'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  export default {
    components: {
      VuexyLogo,
      BCard,
      BButton,
      BCardTitle,
      BCardText,
      BForm,
      BFormGroup,
      BInputGroup,
      BLink,
      BFormInput,
      BInputGroupAppend,
      ValidationProvider,
      ValidationObserver,
    },
    props:{
      info : {}
    },  
    data() {
      return { 
      }
    },
    computed: { 
    },
    methods: { 
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  