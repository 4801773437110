<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo ">
                <!-- <vuexy-logo /> -->

                <h1 class="brand-text text-primary ml-1" style="font-size:35px;">
                    PayPSI <small>Fakultas Psikologi</small>
                </h1><br>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img fluid :src="imgUrl" alt="Register V2" />
                </div>
            </b-col>
            <!-- /Left Text-->
            <!-- Register-->
            <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5" v-if="st=='0'">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1  mt-5">
                        Registrasi Akun 🚀
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Sistem PayPSI Fakultas Psikologi
                    </b-card-text>
                    <!-- form -->

                    <validation-observer ref="registerForm">
                        <b-form class="auth-register-form mt-2">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group label="NIM" label-for="nim">
                                        <validation-provider #default="{ errors }" name="Nomor Induk Mahasiswa"
                                            rules="required|length:13">
                                            <b-form-input id="nim" v-model="registrasi.nim" @input="cekNim()" name="nim"
                                                size="sm" :state="errors.length > 0 ? false:null"
                                                placeholder="F.111.13.0001" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider> {{  }}
                                        <small style="font-size: 10px;;"><i>** Nim akan dignakan sebagai
                                                username</i></small>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" v-if="cek_nim =='1'">
                                    <b-form-group label="Nama Mahasiswa" label-for="Nama_Mahasiswa">
                                        <validation-provider #default="{ errors }" name="Nama kelompot Perikanan"
                                            rules="required">
                                            <b-form-input id="register-email" v-model="registrasi.nm_lengkap"
                                                name="Nama_Mahasiswa" size="sm" :state="errors.length > 0 ? false:null"
                                                placeholder="Nama Lengkap Mahasiswa" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                            </b-row>
                            <b-row v-if="cek_nim =='0'">
                                <b-col>
                                    <b-alert v-height-fade.appear variant="danger" show="show" class="mb-0">
                                        <div class="alert-body">
                                            <feather-icon icon="InfoIcon" class="mr-50" />
                                            {{ pesan }}
                                        </div>
                                    </b-alert>
                                </b-col>
                            </b-row>



                            <!-- username -->
                            <b-row v-if="cek_nim =='1'">
                                <b-col md="6">
                                    <b-form-group label="Tahun Angkatan" label-for="th_angkatan">
                                        <validation-provider #default="{ errors }" name="th_angkatan" rules="required">
                                            <b-form-input id="th_angkatan" type="number"
                                                v-model="registrasi.th_angkatan" size="sm" name="th_angkatan"
                                                :state="errors.length > 0 ? false:null" placeholder="2014" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                    <b-form-group label="No Hp/Wa" label-for="register-no_hp">
                                        <validation-provider #default="{ errors }" name="no_hp" rules="required">
                                            <b-form-input id="register-no_hp" type="number" v-model="registrasi.no_hp"
                                                size="sm" name="register-no_hp" :state="errors.length > 0 ? false:null"
                                                placeholder="085742287875" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group label="Kelas" label-for="kelas">
                                        <validation-provider #default="{ errors }" name="kelas" rules="required">
                                            <b-form-select v-model="registrasi.kelas" size="sm"
                                                :state="errors.length > 0 ? false:null" :options="rs_kelas" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                    <!-- email -->
                                    <b-form-group label="Email" label-for="register-email">
                                        <validation-provider #default="{ errors }" name="Email">
                                            <b-form-input id="register-email" v-model="registrasi.email"
                                                name="register-email" size="sm" :state="errors.length > 0 ? false:null"
                                                placeholder="john@example.com" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>

                                </b-col>
                            </b-row>



                            <!-- password -->
                            <div v-if="cek_nim =='1'">
                                <b-form-group label-for="register-password" label="Password">
                                    <validation-provider #default="{ errors }" name="Password" rules="required">
                                        <b-input-group class="input-group-merge"
                                            :class="errors.length > 0 ? 'is-invalid':null">
                                            <b-form-input id="register-password" v-model="registrasi.password" size="sm"
                                                class="form-control-merge" :type="passwordFieldType"
                                                :state="errors.length > 0 ? false:null" name="register-password"
                                                placeholder="············" />
                                            <b-input-group-append is-text>
                                                <feather-icon :icon="passwordToggleIcon" class="cursor-pointer"
                                                    @click="togglePasswordVisibility" />
                                            </b-input-group-append>
                                        </b-input-group>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group label-for="register-password" label="Konformasi Password">
                                    <validation-provider #default="{ errors }" name="Password" rules="required">
                                        <b-input-group class="input-group-merge"
                                            :class="errors.length > 0 ? 'is-invalid':null">
                                            <b-form-input id="register-password" v-model="registrasi.password2"
                                                size="sm" class="form-control-merge" :type="passwordFieldType"
                                                :state="errors.length > 0 ? false:null" name="register-password"
                                                placeholder="············" />
                                            <b-input-group-append is-text>
                                                <feather-icon :icon="passwordToggleIcon" class="cursor-pointer"
                                                    @click="togglePasswordVisibility" />
                                            </b-input-group-append>
                                        </b-input-group>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div v-if="registrasi.password2 != registrasi.password">
                                <i class="text-danger">*** Maaf password konfirmasi belum sesuai dengan password..!!</i>
                            </div>
                            <div v-if="registrasi.password2 == registrasi.password">
                                <b-button variant="primary" block type="submit" @click.prevent="validationForm"
                                    v-if="cek_nim =='1'">
                                    <i class="fa fa-user mr-1"></i> Sign up
                                </b-button>
                            </div>

                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <span>Sudah Memiliki Akun ?</span>
                        <router-link to="/">
                            <span>&nbsp;Silahkan Login disini</span>
                        </router-link>

                    </p>

                    <!-- divider -->
                    <div class="divider my-2">
                        <div class="divider-text">
                            PayPSI By Psikologi TECT
                        </div>
                    </div>


                </b-col>
            </b-col>
            <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5" v-if="st == '1'">
                <registrasi_info :info=info v-if="st == '1'"></registrasi_info>
            </b-col>
            <!-- /Register-->
        </b-row>

    </div>
</template>

<script>
    import Base from '@/config/Mixins_base';
    import registrasi_info from './RegistrasiInfo.vue';
    /* eslint-disable global-require */
    import axios from '@/config/Axios'
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {
        BRow,
        BCol,
        BLink,
        BButton,
        BForm,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BCardTitle,
        BCardText,
        BFormSelect,
        BAlert
    } from 'bootstrap-vue'
    import {
        required,
        email,
        length,
    } from '@validations'
    import {
        togglePasswordVisibility
    } from '@core/mixins/ui/forms'
    import store from '@/store/index'

    export default {
        components: {
            VuexyLogo,
            BRow,
            BImg,
            BCol,
            BLink,
            BButton,
            BForm,
            BCardText,
            BCardTitle,
            BFormCheckbox,
            BFormGroup,
            BFormInput,
            BInputGroup,
            BInputGroupAppend,
            // validations
            ValidationProvider,
            ValidationObserver,
            registrasi_info,
            BFormSelect,
            BAlert
        },
        mixins: [togglePasswordVisibility, Base],
        data() {
            return {
                rs_kelas: [{
                        value: 'Pagi',
                        text: 'Pagi'
                    },
                    {
                        value: 'Sore',
                        text: 'Sore'
                    },
                    {
                        value: 'Karyawan',
                        text: 'Karyawan'
                    },
                ],
                registrasi: {},
                cek_nim: 1,
                pesan: {},
                username_info: {},
                status: '',
                username: '',
                userEmail: '',
                password: '',
                sideImg: require('@/assets/images/pages/register-v2.svg'),
                required,
                email,
                length,
                st: "0",
                info: {},
                tokenkey: ""
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            },
        },
        mounted() {
            this.st = '0';
            this.cektokenkey();
        },
        methods: {
            async cektokenkey() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/registrasi/crf_tkn',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.tokenkey = response.data.result;
                        //self.rs_level = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async cekNim() {
                const self = this;
                const nim = self.registrasi.nim;
                if (nim.length >= 13) {
                    await axios({
                            method: 'PUT',
                            url: '/api/registrasi/cek_nim',
                            data: {
                                username: self.registrasi.nim,
                                tokenkey: self.tokenkey
                            },
                            headers: {

                            }
                        })
                        .then(function (response) {
                            self.pesan = response.data.message;
                            self.cek_nim = response.data.result

                            self.cek_nim = response.data.result
                        }).catch(err => {
                            self.pesan = err.message;
                            self.notification('warning', "Error", err.message);
                        });
                }


            },
            async cek_username() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/registrasi/cek_username',
                        data: {
                            username: self.registrasi.username
                        },
                        headers: {

                        }
                    })
                    .then(function (response) {
                        self.username_info = response.data.result;
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async act_register() {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/registrasi/add_user',
                        data: {
                            username: self.registrasi.nim,
                            password: self.registrasi.password,
                            nm_lengkap: self.registrasi.nm_lengkap,
                            email: self.registrasi.email,
                            no_hp: self.registrasi.no_hp,
                            th_angkatan: self.registrasi.th_angkatan,
                            kelas: self.registrasi.kelas,
                            tokenkey: self.tokenkey
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.info = response.data;
                        self.st = '1';
                        self.notification('primary', "Selamat..", response.data.message);

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            validationForm() {
                this.$refs.registerForm.validate().then(success => {
                    if (success) {
                        this.act_register();
                        // this.$router.push({
                        //     name: 'registrasi_info'
                        // })
                    }
                })
            },
        },
    }
    /* eslint-disable global-require */
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>